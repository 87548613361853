@import url(https://fonts.googleapis.com/css2?family=Ubuntu&display=swap);
body, html, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
.App {
  min-height: 100vh;
  flex-direction: column;
}

a {
  color: #0e0e00 !important;
  text-decoration: none !important;
}

a:hover,
a:active,
a:focus {
  color: #252525 !important;
}

i {
  cursor: var(--cursor-you-img);
}

img {
  width: 100px;
}

